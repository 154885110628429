import * as React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import { page } from "./post.module.css";

const PostPage = ({ data, location }) => {
  const post = data.markdownRemark;
  return (
    <Layout pageTitle={post.frontmatter.title}>
      <Seo
        pageTitle={post.frontmatter.title}
        pageDescription={post.frontmatter.description}
        pagePath={location.pathname}
      />
      <article className={page}>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;

export default PostPage;
